import * as React from "react";

function BrandIconFunc(props: { className: string; invert: boolean }) {
  return (
    <div className={props.className}>
      <svg version="1.1" width="100%" height="100%" viewBox="0 0 10 10">
        <path
          className={props.invert ? "fill-current" : "fill-brand"}
          d="M 2.4075572,9.3520944 C -0.43709283,7.6766599 -0.78024807,4.8581374 1.4810548,1.7422306 3.0177191,-0.37517645 3.0177191,-0.37517645 3.9975712,0.74369905 c 0.9798497,1.11887505 0.9798497,1.11887505 1.9596971,0 0.9798521,-1.1188755 0.9798521,-1.1188755 2.5165141,0.99853155 1.7147496,2.362795 1.9643446,4.0868339 0.8506875,5.8759818 -1.4073282,2.2609437 -4.644343,3.0723756 -6.9169127,1.733882 z"
        />
        <path
          className={props.invert ? "fill-brand" : "fill-current"}
          d="M 3.2659356,3.651064 C 3.1579895,2.9533021 2.6949727,3.4836472 2.565047,3.8289991 2.1880405,4.5586362 1.9910333,5.5484097 1.9569798,6.5522651 2.0120222,7.1240528 2.3673635,6.9201268 2.5059,6.6328293 2.9959644,5.9604197 3.3092119,4.872101 3.2718351,3.7304271 Z M 6.1639767,3.9217854 C 6.1613541,4.7069205 5.7884694,5.2033138 5.5402391,5.7552377 5.1896558,6.4312286 4.7228455,7.0892771 4.144295,7.0216199 3.8310271,7.0141526 3.5667859,6.3040333 3.4652189,6.0857449 3.1807217,6.992435 2.7286305,7.8188041 2.1208606,8.0488855 1.7351393,8.1642368 1.4377254,7.4628544 1.358345,6.8724819 1.2377281,6.006322 1.4153148,5.1635424 1.6035895,4.4144919 1.8568018,3.5123216 2.2651377,2.6945923 2.8249499,2.3560301 3.1125807,2.2739891 3.5027674,2.1969971 3.7165203,2.683519 3.9366353,3.0632429 3.9162682,3.5923058 3.8770611,4.0485375 3.8358927,4.606695 3.8073561,5.3133774 4.1014763,5.7411309 4.4243747,6.0310407 4.600118,6.105403 5.2352276,5.2879841 5.6272449,4.7797307 5.6620785,4.4504156 5.7748918,4.0513872 6.0992417,3.1886415 6.1834205,3.6869245 6.1639767,3.9217854 Z"
        />
        <path
          className={props.invert ? "fill-brand" : "fill-current"}
          d="M 8.8799471,5.0412869 C 8.8944781,5.5396666 8.6512628,5.8584098 8.486854,6.189215 7.9787575,7.0351347 7.2940287,7.7236562 6.529176,7.5645194 5.9822086,7.3932433 5.6229273,6.3594004 5.6521628,5.4652083 5.6484763,4.277757 5.9027464,3.1333861 6.3315243,2.2758745 6.5221294,1.9328806 6.8069432,1.5565962 7.1123437,1.7745456 7.4523289,2.0817918 7.7026407,2.8109264 7.6200903,3.4387867 7.5328859,4.051173 7.1030663,3.7899167 7.0534081,3.2343084 6.8563794,2.5654347 6.639409,3.4485602 6.5572409,3.7482415 6.381337,4.4329938 6.2856544,5.2310695 6.3839048,6.0097092 6.5228512,6.648194 6.9518081,6.5894951 7.2319014,6.4228368 7.7216122,6.2138222 8.1209332,5.6762654 8.4676843,5.0879689 8.5705556,4.9630539 8.791991,4.7014531 8.8799471,5.0412869 Z"
        />
      </svg>
    </div>
  );
}

export const BrandIcon: React.FC<{ className: string; invert: boolean }> = BrandIconFunc;
