import * as React from "react";
import { BrandIcon } from "../components/brandicon";

function HeaderFunc() {
  return (
    <div className="relative bg-brand text-white">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center p-2 md:justify-start md:space-x-10">
          <div className="flex justify-start w-0 flex-1">
            <a href="#">
              <span className="sr-only">Archive Cat</span>
              <BrandIcon className="w-12 h-12" invert={true} />
            </a>
            <div className="ml-4 flex items-center justify-end">
              <h1 className="text-white items-center text-xl xs:text-2xl sm:text-3xl md:text-4xl align-middle">Archive Cat</h1>
            </div>
          </div>
          <div className="flex items-center justify-end md:flex-1 w-0">
            <a
              href="#"
              className="hidden xs:inline-flex ml-8 whitespace-nowrap items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-brand bg-white hover:bg-brand-800 hover:text-white"
            >
              Learn more
            </a>
            <a
              href="#"
              className="xs:hidden ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-brand bg-white hover:bg-brand-800 hover:text-white"
            >
              More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export const Header: React.FC = HeaderFunc;
